import {useAppAxios} from "../../app/hooks";
import {ApiEnum} from "../enums/ApiEnum";
import {CollectionExportInterface} from "../interfaces/CollectionExportInterface";
import {store} from "../../app/store";
import {ExportTypeInterface} from "../interfaces/ExportTypeInterface";
import {ProductExportInterface} from "../interfaces/ProductExportInterface";
import { AxiosResponse } from "axios";

export const exportCollections = async (params: CollectionExportInterface) => {
    const {root} = store.getState()
    const {authToken} = root

    return new Promise((resolve, reject) => {
        if (!params.selectedExportType) {
            reject('No export type selected')
        }

        resolve(
            useAppAxios.get(ApiEnum.COLLECTIONS + '/' + params.collection.id + '/export/' + params.selectedExportType?.type, {
                method: 'GET',
                responseType: 'blob',
                headers: {'Authorization': `Bearer ${authToken}`},
            }).then((response) => {
                download(response, params.title, params.selectedExportType?.fileType)
            }).catch(function (error) {
                return error
            })
        )
    })
}

export const exportProductSelection = async (params: ProductExportInterface) => {
    const {root} = store.getState()
    const {authToken} = root

    return new Promise((resolve, reject) => {
        if (!params.selectedExportType) {
            reject('No export type selected')
        }

        if (Array.isArray(params.products)) {
            resolve(
                useAppAxios.request({
                    url: ApiEnum.PRODUCT_DETAILS + '/export/',
                    method: 'POST',
                    responseType: 'blob',
                    headers: {'Authorization': `Bearer ${authToken}`},
                    data: {
                        productIds: params.products.map(product => product.id),
                        type: params.selectedExportType?.type,
                        title: "Product Range"
                    }
                }).then((response) => {
                    download(response, params.title, params.selectedExportType?.fileType)
                }).catch(function (error) {
                    return error
                })
            )
        }
    })
}

export const exportProduct = async (params: ProductExportInterface) => {
    const {root} = store.getState()
    const {authToken} = root

    return new Promise((resolve, reject) => {
        if (!params.selectedExportType) {
            reject('No export type selected')
        }

        if (!Array.isArray(params.products)) {
            resolve(
                useAppAxios.get(ApiEnum.PRODUCT_DETAILS + '/' + params.products.id + '/export/' + params.selectedExportType?.type, {
                    method: 'GET',
                    responseType: 'blob',
                    headers: {'Authorization': `Bearer ${authToken}`},
                }).then((response) => {
                    download(response, params.title, params.selectedExportType?.fileType)
                }).catch(function (error) {
                    return error
                })
            )
        }
    })
}

export const exportProducts = async (params: { exportType: ExportTypeInterface | null; productType: string; title: string; }) => {
    const {root} = store.getState()
    const {authToken} = root

    return new Promise((resolve, reject) => {
        if (!params.exportType) {
            reject('No export type selected')
        }

        resolve(
            useAppAxios.request({
                url: ApiEnum.PRODUCT_RANGE + '/export/' + params.exportType?.type,
                method: 'POST',
                responseType: 'blob',
                headers: {'Authorization': `Bearer ${authToken}`},
                data: {
                    type: params.productType,
                }
            }).then((response) => {
                download(response, params.title, params.exportType?.fileType)
            }).catch(function (error) {
                return error
            })
        )
    })
}

const download = ( response: AxiosResponse<any> , title?: string, fileType?: string) => {
    if (!fileType) {
        return;
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title + '.' + fileType);
    document.body.appendChild(link);
    link.click();
}

export const downloadFile = async (props: {url: string, fileName: string}) => {
    const {url, fileName} = props;

    if (!url) {
        return;
    }

    const fileType = url.split('.').pop();

    try {
        const response = await useAppAxios.get(url, {
            responseType: 'blob'
        });
        const blob = new Blob([response.data]);
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Download failed:', error);
    }
};
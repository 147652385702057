import {Translate} from "../../utils/Translate";
import {ProductInterface} from "../../interfaces/ProductInterface";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectProduct} from "../../../pages/productRangePage/ProductRangeSlice";
import {Link} from "react-router-dom";
import {RouteEnum} from "../../enums/RouteEnum";
import {useGetUserInfoQuery} from "../../../app/rootReducer";
import {currencyFormatter} from "../../utils/currencyFormatter";

interface Props {
    product: ProductInterface;
    disableCheckbox?: boolean;
}

export const ProductListItem = (props: Props) => {

    const dispatch = useAppDispatch()

    const userData = useGetUserInfoQuery()
    const {activeLanguage} = userData?.data || {activeLanguage: null}

    const {selectedProducts} = useAppSelector(state => state.productRange);
    const {product} = props
    let disableCheckbox = props.disableCheckbox

    if (props.disableCheckbox && !!selectedProducts.includes(product)) {
        disableCheckbox = false
    }

    return (
        <label className={"c-products-list__sub-item__item " + (selectedProducts.includes(product) ? 'is-active' : '')}>
            <div className="c-products-list__sub-item__item__left">
                <input type="checkbox" className={"checkbox " + (disableCheckbox ? "checkbox-disabled" : "")}
                       checked={!!selectedProducts.includes(product)}
                       onChange={() => dispatch(selectProduct(product))} disabled={disableCheckbox}/>
                {/*<div className="favourites">*/}
                {/*    <img src="/images/favourites.svg" alt="favourites"/>*/}
                {/*</div>*/}
                <Link to={RouteEnum.PRODUCT_RANGE + `/${product.id}`}>
                    {!!activeLanguage && <span>{Translate(product?.label, activeLanguage)}</span>}
                </Link>
            </div>
            {product.identifier &&
                <div className="c-products-list__sub-item__item__center">
                    <span>{product.identifier}</span>
                </div>
            }
            <div className="c-products-selected__item__center c-products-selected__item__center--right">
                {product.connection &&
                    <span>{product.connection}</span>
                }
            </div>
            {(product.prices && product.prices.length > 0) &&
                <div className="c-products-list__sub-item__item__right">
                    {!!activeLanguage &&
                        <span>{currencyFormatter(product.prices, activeLanguage)}</span>
                    }
                </div>
            }
        </label>
    )

}





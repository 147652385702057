import {SvgDownload} from "../svg/SvgDownload";
import {ProductDetailsDataInterface, ProductDownload} from "../../interfaces/ProductDetailsDataInterface";
import {LanguageInterface} from "../../interfaces/LanguageInterface";
import {useState} from "react";
import {StringTranslation} from "../../utils/StringTranslation";
import {useGetTranslationsQuery} from "../../../app/rootReducer";
import {downloadFile} from "../../utils/Export";

interface Props {
    data: ProductDetailsDataInterface,
    activeLanguage: LanguageInterface,
    title: string,
    type: string
}

interface DownloadItemProps {
    file: {
        name: string,
        type: string,
        url: string
    },
    activeLanguage: LanguageInterface
}

interface NavItemProps {
    isActive: boolean,
    labelName: string,
    displayName: string,
    handleTabClick: (tab: string) => void
}

export const ProductDownloads = (props: Props) => {

    const {title, type, data, activeLanguage} = props;
    let marketingDownloads = data.marketingDownloads
    let technicalDownloads = data.technicalDownloads
    let marketingNavItems = data.marketingDownloadNavItems
    let technicalNavItems = data.technicalDownloadNavItems
    const {data: translations} = useGetTranslationsQuery();
    const [selectedTabMarketing, setSelectedTabProduct] = useState('all')
    const [selectedTabTechnical, setSelectedTabTechnical] = useState('all')

    const handleTabClick = (tab: string) => {
        type === 'marketing' ? setSelectedTabProduct(tab) : setSelectedTabTechnical(tab)
    }
    const getTabIsActive = (tab: string) => {
        return type === 'marketing' ? selectedTabMarketing === tab : selectedTabTechnical === tab
    }


    if ((type === 'marketing' && marketingDownloads[0].value.length > 0) || (type === 'technical' && technicalDownloads[0].value.length > 0)) {
        return (
            <div className="c-product-downloads">
                <div className="c-product-downloads__top">
                    <div className="c-product-downloads__top__title">
                        <h3>{title}</h3>
                        {type === 'marketing'
                            ? !!data.downloadsCount.marketing &&
                            <span>{data.downloadsCount.marketing}</span>
                            : !!data.downloadsCount.technical &&
                            <span>{data.downloadsCount.technical}</span>
                        }
                    </div>
                    <div className="c-product-downloads__top__nav">
                        {type === 'marketing' ? marketingNavItems.map((item, index) => (
                            <DownloadNavItem
                                key={index}
                                isActive={getTabIsActive(item)}
                                displayName={StringTranslation(item, translations, activeLanguage)}
                                labelName={item}
                                handleTabClick={handleTabClick}
                            />
                        )) : technicalNavItems.map((item, index) => (
                            <DownloadNavItem
                                key={index}
                                isActive={getTabIsActive(item)}
                                displayName={StringTranslation(item, translations, activeLanguage)}
                                labelName={item}
                                handleTabClick={handleTabClick}
                            />
                        ))
                        }
                    </div>
                </div>
                <div className="c-product-downloads__bottom">
                    {type === 'marketing' ? (
                        marketingDownloads.filter(download =>
                            selectedTabMarketing === 'all' ? download : download.code === selectedTabMarketing ? download : false).map((downloadItems: ProductDownload) => {
                            if (downloadItems.value) {
                                return downloadItems.value.map((download: any, i: number) => {
                                        return (
                                            <DownloadItem
                                                key={i}
                                                activeLanguage={activeLanguage}
                                                file={{name: download.label, type: downloadItems.label, url: download.url}}
                                            />
                                        )
                                    }
                                )
                            } else return null
                        })
                    ) : (
                        technicalDownloads.filter(download => {
                            switch (selectedTabTechnical) {
                                case 'all':
                                    return download
                                case 'manuals':
                                    return download.code === 'manuals' || download.code === 'service' ||
                                        download.code === 'troubleshooting'
                                case 'drawings':
                                    return download.code === 'drawings' || download.code === 'electrical_diagrams' ||
                                        download.code === 'exploded_views'
                                case 'software':
                                    return download.code === 'software' || download.code === 'special_codes' ||
                                        download.code === 'bin_files'
                                case 'certificates':
                                    return download.code === 'certificates'
                                case 'videos':
                                    return download.code === 'videos'
                                default:
                                    return download
                            }
                        }).map((downloadItems: ProductDownload) => {
                            if (downloadItems.value) {
                                return downloadItems.value.map((download: any, i: number) =>
                                    <DownloadItem
                                        key={i}
                                        activeLanguage={activeLanguage}
                                        file={{name: download.label, type: downloadItems.label, url: download.url}}
                                    />
                                )
                            } else return null
                        })
                    )
                    }

                </div>
            </div>
        )
    }
    return null
}


const DownloadItem = (props: DownloadItemProps) => {
    const {file, activeLanguage} = props
    let {url, name, type} = file
    let typeLabel = type

    const {data: translations} = useGetTranslationsQuery();

    // temp? fix for long prefixes in file names
    if (type === 'Images') {
        name = name.replace(/^[^_]*_/, "");
        name = name.charAt(0).toUpperCase() + name.slice(1);
        typeLabel = StringTranslation('images', translations, activeLanguage);
    }

    return (
        <div className="c-product-downloads__bottom__item">
            <div className="c-product-downloads__bottom__item__left">
                <div onClick={() => downloadFile({
                    url: url,
                    fileName: name,
                })}>
                    {name}
                </div>
            </div>
            <span className="c-product-downloads__bottom__item__middle">{typeLabel}</span>
            <span className="c-product-downloads__bottom__item__right">
                <div onClick={() => downloadFile({
                    url: url,
                    fileName: name,
                })}>
                    <div className="c-product-downloads__download-icon">
                      <SvgDownload color={'#151112'}/>
                    </div>
                    {StringTranslation('download', translations, activeLanguage)}
                </div>
          </span>
        </div>
    )
}

const DownloadNavItem = (props: NavItemProps) => {

    const {isActive, displayName, labelName, handleTabClick} = props;

    return (
        <div
            className={"c-product-downloads__top__nav__item " + (isActive ? " is-active" : '')}
            onClick={() => handleTabClick(labelName)}
        >
            {displayName}
        </div>
    )
}

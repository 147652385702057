import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {breakPointsOlderModelsSlider} from "./BreakPointsCarousel";
import {ProductOlderModelsItem} from "./ProductOlderModelsItem";
import {ProductDetailsDataInterface} from "../../interfaces/ProductDetailsDataInterface";
import {LanguageInterface} from "../../interfaces/LanguageInterface";

interface Props {
    data: ProductDetailsDataInterface,
    activeLanguage: LanguageInterface,
    title: string,
}

export const ProductOlderModels = (props: Props) => {
    const { activeLanguage, title} = props;
    const {olderModelAssociations} = props.data;

    const filledItems = [...olderModelAssociations];

    const ProductOlderModelsNavigationItems = ({ next, previous }: any) => {
        return (
            <div className="c-older-models__header__nav">
                <div
                    className="c-older-models__header__nav__button c-older-models__header__nav__button--left"
                    onClick={() => previous()}
                >
                    <img src="/images/arrow-black.svg" alt="arrow-right"/>
                </div>
                <div
                    className="c-older-models__header__nav__button c-older-models__header__nav__button--right"
                    onClick={() => next()}
                >
                    <img src="/images/arrow-black.svg" alt="arrow-right"/>
                </div>
            </div>
        )
    };

    return (
        <div className="c-older-models">
            <div className="c-older-models__header">
                <h3>{title}</h3>
            </div>
            <div className="c-older-models__slider">
                <Carousel
                    responsive={breakPointsOlderModelsSlider}
                    itemClass="carousel-item-padding"
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ProductOlderModelsNavigationItems />}
                >
                    {filledItems.map((item, index) => (
                        item.label || item.image ? (
                            <ProductOlderModelsItem key={index} title={item.label} image={item.image} id={item.id} activeLanguage={activeLanguage}/>
                        ) : (
                            <div key={index} className="c-older-models__empty-item"/> // Render lege item
                        )
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {breakPointsRelatedSlider} from "./BreakPointsCarousel";
import {ProductRelatedProductsItem} from "./ProductRelatedProductsItem";
import {ProductInterface} from "../../interfaces/ProductInterface";

interface Props {
  products?: ProductInterface[],
  title: string,
}

export const ProductRelatedProducts = (props: Props) => {

  const {title, products} = props;

  if (!products || products.length === 0) return null;

  const ProductRelatedNavigationItems = ({ next, previous }: any) => {
    return (
      <div className="c-related-products__header__nav">
        <div
            className="c-related-products__header__nav__button c-related-products__header__nav__button--left"
            onClick={() => previous()}
        >
          <img src="/images/arrow-black.svg" alt="arrow-right"/>
        </div>
        <div
            className="c-related-products__header__nav__button c-related-products__header__nav__button--right"
            onClick={() => next()}
        >
          <img src="/images/arrow-black.svg" alt="arrow-right"/>
        </div>
      </div>
    )
  };

  return (
    <div className="c-related-products">
      <div className="c-related-products__header">
        <h3>{title}</h3>
      </div>
      <div className="c-related-products__slider">
        <Carousel
          swipeable={false}
          responsive={breakPointsRelatedSlider}
          itemClass="carousel-item-padding"
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ProductRelatedNavigationItems />}
        >
          {products.map((product, index) => (
            <ProductRelatedProductsItem key={index} product={product}/>
            ))}
        </Carousel>
      </div>
    </div>
  )

}
